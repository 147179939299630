import {renderRichText, storyblokEditable} from '@storyblok/react'
import React from 'react'
import {
  ButtonStoryblok,
  HighlightContainerStoryblok,
} from 'types/storyblok-types'
import {TextBlock} from './Text'
import BackgroundMapper from 'util/BackgroundMapper'
import ButtonBlock from 'components/shared/ButtonBlock'
import linkTypeChecker from 'util/linkTypeChecker'

type highlightContainerPropsType = {
  blok: HighlightContainerStoryblok
}

type HighlightBlockPropsType = {
  title: HighlightContainerStoryblok['title']
  text: string
  blok: HighlightContainerStoryblok
  backgroundColor: HighlightContainerStoryblok['background_color'][0]['background_color']
  button?: ButtonStoryblok
}

export function HighlightBlock({
  title,
  text,
  blok,
  backgroundColor,
  button,
}: HighlightBlockPropsType) {
  return (
    <div
      className={`overflow-hidden rounded-dats ${BackgroundMapper(
        backgroundColor,
      )} w-full p-[30px]`}
      {...storyblokEditable(blok)}
    >
      <span className="text mb-5 block font-rubik font-semibold">{title}</span>
      <TextBlock innerHtml={text} />
      {button ? (
        <ButtonBlock
          title={button.button_label}
          type="button"
          styling={button.button_color}
          className="mt-6"
          cta_url={button.link ? linkTypeChecker(button.link) : null}
          cta_target={button.link.target}
        />
      ) : null}
    </div>
  )
}

const HighlightContainer = ({blok}: highlightContainerPropsType) => {
  return (
    <HighlightBlock
      title={blok.title}
      text={renderRichText(blok.text)}
      backgroundColor={
        blok.background_color.length > 0
          ? blok.background_color[0]['background_color']
          : 'white'
      }
      blok={blok}
      button={blok?.button?.[0]}
    />
  )
}

export default HighlightContainer
